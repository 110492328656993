import React, {useState, useContext, useEffect, useRef} from 'react';
import {Context} from '../../AppContext';
import ContentWrapper from '../../Components/Layout/ContentWrapper';
import {Heading} from '../../Widgets';
import {navigate} from 'gatsby';
import queryString from 'query-string';
import {message} from 'antd';

export default function AuthRedirect({location}) {
  const app = useContext(Context);

  useEffect(() => {
    app.actions.setLoading(true);
  }, [app.actions]);

  useEffect(() => {
    (async () => {
      const {token} = queryString.parse(location.search);
      if (!token) {
        alert('無法取得認證');
      }
      if (typeof window !== 'undefined' && token) {
        try {
          window.localStorage.setItem('token', token);
          await app.actions.autoLogin();
          message.success('社群登入成功');
          navigate('/profile');
        } catch (ex) {
          console.warn(ex);
          alert('社群登入發生錯誤');
        }
      }
    })();
  }, [app.actions, location]);

  return (
    <ContentWrapper>
      <div
        style={{
          maxWidth: 600,
          minHeight: 300,
          margin: '0 auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Heading>正在社群登入...</Heading>
      </div>
    </ContentWrapper>
  );
}
